.TestManagementPage {
    margin-top: 15px;
}

.search-row {
    margin-bottom: 10px;
    margin-top: -5px;
}

.filter-input {
    height: 30px;
}

.tm-card {
    min-height: 280px;
    max-height: 280px;
}

.center-cards {
    margin-top: 7%;
}

.right-cards {
    margin-top: 13%;
}

.table {
    font-size: 11pt;
}

.content-column {
    min-height: 220px;
    max-height: 220px;
    overflow-y: scroll;
}

.verdict-content-column {
    min-height: 560px;
    max-height: 560px;
    overflow-y: scroll;
}

.show-file-modal {
    max-width: 80vw;
    max-height: 80vw;
}