
.DocumentationPage {
    margin-top: 65px; /* navbar-height + 5px */
    margin-left: 30px;
    margin-right: 0px;
    font-size: 16px;
    width: 99%;
    padding-right: 10px;
}

.DocumentationRow {
    height: 1700px;
    margin-left: 30px;
    margin-top: 100px;
}


.main-doc-row {
    align-self: left;
}

.ref-col-idx {
    margin-left: 10px;
    width: 20px;
}

.p-class-text-ref {
    margin-bottom: -2px;
}

.phase-row {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
}

.equation-row {
    text-align: center;
}

.listing-row {
    margin-top: 15px;
}

.listing-caption {
    margin-left: 30px;
    margin-top: -10px;
    margin-bottom: 20px;
}

.pot-step-description {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.example-listing {
    margin-top: 30px;
}

.gdc-image-row {
    height: 300px;
}

pre {
    white-space: pre-wrap;
    background-color: rgb(236, 235, 235);
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
}

h4 {
    margin-top: 25px;
}

a {
    padding-left: 5px;
    padding-right: 5px;
}

b {
    padding-left: 5px;
    padding-right: 5px;
}

.header-row {
    margin-bottom: 15px;
}

.gdc-image-row {
    height: 450px;
}

brake-model-row {
    height: 500px;
}