.nav {
    margin-top: 20px;
    font-size: 17px;
}

.test-cloud-spinner {
    margin-right: 10px;
    margin-top: -5px;
}

.main-tab-row {
    height: 1600px;
}