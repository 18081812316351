/* Styling for the main page */
.MainPage {
  text-align: left;
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: left;
  align-items: left;
  justify-content: left;
  font-size: 20px;
  color: rgb(0, 0, 0);
}

/* Styling for a navigation bar */
.navbar {
  font-size: 18px;
  height: 50px;
}
