.POTPage {
    margin-top: 15px;
}

.pre {
    margin-top: 10px;
}

.potCenterRow {
    min-height: 70px;
    background: rgb(238, 238, 238);
}

.pot-center-clear-button {
    margin-top: 10px;
}

.pot-log-pre {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 15px;
    width: '95%';
    background: 'rgb(238, 238, 238)';
    max-height: 600px;
}

.card {
    margin-top: 15px;
}

.card-header {
    height: 40px;
    font-size: 15px;
}

.leftColumn {
    display: block;
}

.ParameterLabel {
    font-size: 15px;
}

.fault-domain-file-select {
    width: 100%;
    height: 35px;
}

.select-fault-domain-form {
    margin-top: 10px;
    font-size: 18px;
}

.select-harness-form {
    margin-top: 10px;
    font-size: 18px;
}

.select-property-form {
    margin-top: 10px;
    font-size: 18px;
}

.pot-fault-domain-list {
    margin-left: -40px;
    min-height: 400px;
    max-height: 400px;
    overflow-y: scroll;
}

.pot-property-list {
    margin-left: -40px;
    min-height: 400px;
    max-height: 400px;
    overflow-y: scroll;
}

.table {
    font-size: 16px;
}

.list-items {
    font-size: 16px;
}

.harness-file-select {
    width: 100%;
    height: 35px;
}

.property-file-select {
    width: 100%;
    height: 35px;
}

.harness-file-name-header {
    font-size: 16px;
    margin-top: 15px;
}

.harness-file-name {
    font-size: 16px;
    margin-top: 15px;
    height: 80px;
    font-weight: bold;
}

.fault-domain-name-header {
    font-size: 16px;
    margin-top: 15px;
}

.fault-domain-name {
    font-size: 16px;
    margin-top: 15px;
    height: 80px;
    font-weight: bold;
}

.pot-label {
    margin-top: 15px;
}