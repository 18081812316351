.ContentPage {
    margin-top: 55px; /* navbar-height + 5px */
    margin-left: 10px;
    margin-right: 5px;
    font-size: 20px;
    width: 100%;
    padding-right: 10px;
}

.AboutRow {
    height: 700px;
}

.btn {
    margin-right: 2px;
}

.footer {
    font-size: 10pt;
}

.footer-column {
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.address-pre {
    margin-top: 50px;
    font-size: 16px;
}