.SFSMPage {
    margin-top: 15px;
}

.pre {
    margin-top: 10px;
}

.sfsmCenterRow {
    min-height: 70px;
    background: rgb(238, 238, 238);
}

.sfsm-center-clear-button {
    margin-top: 10px;
}

.sfsm-log-pre {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 15px;
    width: '95%';
    background: 'rgb(238, 238, 238)';
    max-height: 600px;
}

.card {
    margin-top: 15px;
}

.card-header {
    height: 40px;
    font-size: 15px;
}

.leftColumn {
    display: block;
}

.ParameterLabel {
    font-size: 15px;
}

.model-file-select {
    width: 100%;
    height: 35px;
}

.select-model-form {
    margin-top: 10px;
    font-size: 18px;
}

.model-file-names {
    font-size: 16px;
}

.harness-file-select {
    width: 100%;
    height: 35px;
}

.harness-file-name-header {
    font-size: 16px;
    margin-top: 15px;
}

.harness-file-name {
    font-size: 16px;
    margin-top: 15px;
    height: 80px;
    font-weight: bold;
}

.get-harness-builder {
    margin-top: 10px;
}

.table {
    font-size: 16px;
}

.additional-states-picker {
    width: 45px;
    height: 40px;
}

.model-list {
    font-size: 16px;
}

.model-checkbox {
    margin-right: 10px;
}

.select-formula-form {
    margin-top: 10px;
    font-size: 18px;
}

.formula-list {
    font-size: 16px;
}

.formula-checkbox {
    margin-right: 10px;
}

.show-picture-row {
    margin-top: 50px;
}

.btn-generate-picture {
    margin-bottom: 10px;
}

.sfsm-spinner {
    margin-left: 20px;
}